import { PropsWithChildren } from 'react';
import { Box, Center, useMantineTheme } from '@mantine/core';
import Head from 'next/head';
import Image from 'next/image';

type Props = {
  title: string;
};

export const CenteredLayout = ({ title, children }: PropsWithChildren<Props>) => {
  const theme = useMantineTheme();

  return <>
    <Head>
      <title>{ `${title} | ZippyAssist` }</title>
    </Head>
    <Box pt={70} style={{ height: '100vh', backgroundColor: theme.colors.gray[0] }}>
      <Center mb={30}>
        <Image alt="Zippy Logo" src="/images/zippy-logo.png" width={200} height={46} />
      </Center>

      { children }
    </Box>
  </>;
};
