import { GetServerSideProps } from 'next';
import { LoginPage } from 'src/pages/auth/login';
import { CenteredLayout } from '@components/layout/CenteredLayout';
import { ExtendedNextPage } from './_app';
import { AuthChallengeProvider } from '../src/pages/auth/forms/AuthChallenge.context';
import { authProvider } from '../src/providers';

export const Login: ExtendedNextPage = () => {
  return <CenteredLayout title="Login">
    <AuthChallengeProvider>
      <LoginPage />
    </AuthChallengeProvider>
  </CenteredLayout>;
};

Login.noLayout = true;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { authenticated } = await authProvider().check(context);

  if (authenticated) {
    return {
      redirect: {
        permanent: false,
        destination: '/'
      }
    }
  }

  return {
    props: {}
  };
};

export default Login;
