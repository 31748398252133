import Link from 'next/link';
import { useRouter } from 'next/router';
import { useInvalidate, useLogin } from '@refinedev/core';
import { Group, Paper, Button, Anchor, PasswordInput, TextInput, Stack, Alert } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import * as Yup from 'yup';
import { useState } from 'react';
import { useAuthChallenge } from './AuthChallenge.context';
import { EMAIL_REGEX } from '@components/ui/EmailRegex';

type Props = {};

const schema = Yup.object().shape({
  email: Yup.string().matches(EMAIL_REGEX, 'Enter a valid email').required('Enter an email'),
  password: Yup.string().required('Enter a password'),
});

export function SignInForm({}: Props) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { mutate: login, isLoading} = useLogin();
  const router = useRouter();
  const invalidate = useInvalidate();
  const { setIsChallenged } = useAuthChallenge();

  const redirectPath = router.query?.to ?? null;

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      email: '',
      password: ''
    },
  });

  const handleSubmit = async (values) => {
    invalidate({ invalidates: ['all'] });
    login({ ...values, redirectPath }, {
      onSuccess: (data) => {
        setIsSuccess(data.success);
        setError(data.error?.message || null);
        setIsChallenged(data.two_factor);
      },
    });
  }

  return <>
    {
      !isLoading && error && <>
        <Alert icon={<IconAlertCircle size={16} />} variant="filled" color="red" mt="md">{ error }</Alert>
      </>
    }

    <Paper withBorder shadow="xs" p={30} mt={30} radius="md">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput label="Email"
                     placeholder="your@email.com"
                     {...form.getInputProps('email')} />

          <PasswordInput label="Password"
                         placeholder="Your password"
                         {...form.getInputProps('password')} />
        </Stack>

        <Group justify="right" mt="md">
          <Link href="/password/reset">
            <Anchor component="span" size="sm">Forgot password?</Anchor>
          </Link>
        </Group>

        <Button fullWidth mt="xl" type="submit"
                loading={isLoading || isSuccess}>
          Sign in
        </Button>
      </form>
    </Paper>
  </>;
}
