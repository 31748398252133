import { Container, Title } from '@mantine/core';
import { SignInForm } from './forms/signin';
import { AuthChallengeForm } from './forms/auth-challenge';
import { useAuthChallenge } from './forms/AuthChallenge.context';

type Props = {};

export function LoginPage({}: Props) {
  const { isChallenged } = useAuthChallenge();

  return <Container style={{ maxWidth: 460 }}>
    <Title ta="center" order={2}>Welcome back!</Title>

    { !isChallenged && <SignInForm /> }
    { isChallenged && <AuthChallengeForm /> }
  </Container>;
}
