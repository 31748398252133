import { useRouter } from 'next/router';
import { useApiUrl, useCustomMutation } from '@refinedev/core';
import { Group, Paper, Button, Anchor, TextInput, Stack, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { useState } from 'react';

type Props = {};

export function AuthChallengeForm({}: Props) {
  const [isRecovery, setIsRecovery] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const router = useRouter();
  const apiUrl = useApiUrl();
  const { mutate: loginChallenge, isLoading: isLoading } = useCustomMutation();

  const redirectPath = router.query?.to ?? '/';

  const schema = Yup.object().shape({
    code: Yup.string().nullable().when({
      is: () => !isRecovery,
      then: (schema) => schema.required('Enter a code'),
    }),
    recovery_code: Yup.string().nullable().when({
      is: () => isRecovery,
      then: (schema) => schema.required('Enter a recovery code'),
    }),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      code: '',
      recovery_code: '',
    }
  });

  const handleSubmit = async (values) => {
    if (isRecovery) {
      delete values.code;
    } else {
      delete values.recovery_code;
    }

    await loginChallenge({ url: `${apiUrl}/two-factor-challenge`, method: 'post', values }, {
      onSuccess: () => {
        setIsSuccess(true);
        router.push(redirectPath as string);
      }
    });
  }

  return <>
    <Paper withBorder shadow="xs" p={30} mt={30} radius="md">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          { !isRecovery && <Text size="sm">Please confirm access to your account by entering the authentication code provided by your authenticator application.</Text>}
          { isRecovery && <Text size="sm">Please confirm access to your account by entering one of your emergency recovery codes.</Text>}

          { !isRecovery && <TextInput label="Code" {...form.getInputProps('code')} autoComplete="off" />}
          { isRecovery && <TextInput label="Recovery Code" {...form.getInputProps('recovery_code')} autoComplete="off" />}

          <Group justify="space-between" align="center">
            { !isRecovery && <Anchor component="span" size="sm" onClick={() => setIsRecovery(true)}>Use a recovery code</Anchor>}
            { isRecovery && <Anchor component="span" size="sm" onClick={() => setIsRecovery(false)}>Use an authentication code</Anchor>}
            <Button type="submit"
                    loading={isLoading || isSuccess}
            >
              Continue
            </Button>
          </Group>
        </Stack>
      </form>
    </Paper>
  </>;
}
